import React from 'react'
import { useSmallerScreen } from 'hooks'
import { Stack } from '@mantine/core'

import { FooterWrapper } from 'components/site'

import Jumbotron from './Jumbotron'
import TheyUseIt from './TheyUseIt'
import Example from './Example'
import QuicklyUse from './QuicklyUse'
import AccurateResearch from './AccurateResearch'
import Teamwork from './Teamwork'
import GoodPoints from './GoodPoints'
import CustomerSay from './CustomerSay'
import NewFeature from './NewFeature'
import Ghost from 'modules/Site/Home/Ghost'
import { PostsOrPages } from '@tryghost/content-api'

interface Props {
  posts?: PostsOrPages
}

const Home = ({ posts }: Props) => {
  const smallerScreen = useSmallerScreen()

  return (
    <Stack spacing={0} className="overflow-x-hidden xs:overflow-x-visible pt-[59px]">
      <Jumbotron />
      <TheyUseIt hiddenTitle={false} smallerScreen={smallerScreen} />
      <Example />
      <QuicklyUse />
      <AccurateResearch />
      <Teamwork />
      <NewFeature />
      <GoodPoints />
      <CustomerSay />
      <FooterWrapper>
        <Ghost posts={posts} />
      </FooterWrapper>
    </Stack>
  )
}

export default Home
