import React from 'react'
import { useSmallerScreen } from 'hooks'
import { Flex, Group, Image, Stack } from '@mantine/core'

import { ContentIntoView } from 'components/ui'
import { ContentContainer, Conversation, FeatureCard, FeatureHeader } from 'components/site'

import resultImg from './images/result.png'
import teamImg from './images/team.png'
import membersImg from './images/members.png'

const Teamwork = () => {
  const smallerScreen = useSmallerScreen()

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <ContentContainer>
            <Group position="center" pt={smallerScreen ? 45 : 86} px={smallerScreen ? 20 : 0}>
              <Stack spacing={32} align="flex-start">
                <FeatureHeader
                  isIntersecting={isIntersecting}
                  title="发现问题，并解决它们"
                  description="基于用户的宝贵反馈，明确团队动作，改善服务体验"
                >
                  <Conversation
                    pcWidth={438}
                    text={['只要用户反馈了，我就能发现问题。', '回收反馈，定位问题，给出改进方案，一气呵成~']}
                  />
                </FeatureHeader>
                <Flex>
                  <FeatureCard
                    color="#F6FAF6"
                    w={smallerScreen ? '100%' : 1200}
                    h={smallerScreen ? 382 : 380}
                    isIntersecting={isIntersecting}
                    title="让调研结果一目了然"
                    direction={smallerScreen ? 'column' : 'row'}
                    description={
                      smallerScreen
                        ? ['随反馈录入，自动生成摘要、数据和图表，快速定位用户问题并推进解决。']
                        : ['随反馈录入，自动生成摘要、数据和图表，快速定位用户', '问题并推进解决。']
                    }
                  >
                    <Image
                      src={resultImg.src}
                      alt="entries"
                      width={smallerScreen ? '100%' : 711}
                      height={smallerScreen ? 195 : 376}
                    />
                  </FeatureCard>
                </Flex>
                <Flex gap={32} direction={smallerScreen ? 'column' : 'row'}>
                  <FeatureCard
                    color="#F6FAF6"
                    isIntersecting={isIntersecting}
                    h={smallerScreen ? 382 : 509}
                    direction="column"
                    title="与团队一起跟进反馈"
                    description={['对关键问题发起讨论，群策群力解决用户问题。']}
                  >
                    <Image
                      src={teamImg.src}
                      alt="platform"
                      width={smallerScreen ? '100%' : 584}
                      height={smallerScreen ? 191 : 324}
                    />
                  </FeatureCard>
                  <FeatureCard
                    color="#F6FAF6"
                    h={smallerScreen ? 382 : 509}
                    direction="column"
                    isIntersecting={isIntersecting}
                    title="共同打造一流产品体验"
                    description={
                      smallerScreen
                        ? ['不限数量协作席位，所有团队成员都可以为产品体验改进添砖加瓦。']
                        : ['不限数量协作席位，所有团队成员都可以为产品体验改', '进添砖加瓦。']
                    }
                  >
                    <Image
                      src={membersImg.src}
                      alt="platform"
                      width={smallerScreen ? '100%' : 584}
                      height={smallerScreen ? 191 : 324}
                    />
                  </FeatureCard>
                </Flex>
              </Stack>
            </Group>
          </ContentContainer>
        )
      }}
    </ContentIntoView>
  )
}

export default Teamwork
