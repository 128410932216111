import React from 'react'
import Head from 'next/head'
import Home from 'modules/Site/Home'
import Logo from 'assets/images/logo-square.png'
import { GetServerSideProps, NextPage } from 'next'
import { useAccountSourceTrack } from 'hooks'
import { useCrisp } from 'plugins/crisp'
import { SiteLayout } from 'components/site'
import { api } from 'plugins/ghost/ghost-client'
import { PostsOrPages } from '@tryghost/content-api'

interface Props {
  referer?: string
  posts?: PostsOrPages
}

const Site: NextPage<Props> = ({ referer, posts }) => {
  useAccountSourceTrack(referer)
  useCrisp()

  return (
    <SiteLayout>
      <Head>
        <title>浩客HowXM官网 - 数字化系统必备的应用内调研、评价、通知工具 - 简单好用的体验分析工具 - NPS调研工具</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        ></meta>
        <meta property="og:image" content={Logo.src} />
        <style>
          {`body {
            min-width: unset !important;
          }`}
        </style>
      </Head>
      <Home posts={posts} />
    </SiteLayout>
  )
}
export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const referer = req?.headers.referer || null
  let posts = null
  try {
    posts = await api.posts.browse({
      filter: `tag:selected`,
      limit: 3,
      fields: ['id', 'title', 'excerpt', 'feature_image', 'feature_image_alt', 'url'],
    })
  } catch (e) {
    console.error(e)
  }

  return {
    props: {
      referer,
      posts,
    },
  }
}

export default Site
