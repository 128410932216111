import { CampaignTemplateCategory } from 'types/domain'

export const categoryNameMap: any = {
  [CampaignTemplateCategory.UserExperience]: '\uD83C\uDF1F 用户体验',
  [CampaignTemplateCategory.UserGuidance]: '\uD83D\uDC4B 用户引导',
  [CampaignTemplateCategory.UserStudy]: '\uD83D\uDE0A 用户研究',
  [CampaignTemplateCategory.OperatingGrowth]: '\uD83D\uDD25 运营增长',
  [CampaignTemplateCategory.ProductsIteration]: '☀\uFE0F 产品迭代',
  [CampaignTemplateCategory.BannerNotice]: '\uD83D\uDCE2 横幅通知',
}
