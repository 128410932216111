import React, { useEffect, useState } from 'react'
import { Group, Stack } from '@mantine/core'

import { SiteTitle } from 'components/site'
import { CampaignTemplate } from 'types/domain'
import MobilePreview from 'components/business/ChannelPreview'
import TemplateSelector from './TemplateSelector'

type Props = {
  isIntersecting: boolean
  templates: CampaignTemplate[]
}

const Mobile = (props: Props) => {
  const { isIntersecting, templates } = props
  const [template, setTemplate] = useState<CampaignTemplate | null>(null)

  useEffect(() => {
    setTemplate(templates[0])
  }, [templates])

  return (
    <Stack spacing={24} align="center">
      <Stack px={20}>
        <SiteTitle ta="center" text="在数字化系统中体验" isIntersecting={isIntersecting} />
      </Stack>
      {template && (
        <>
          <TemplateSelector template={template} templates={templates} onClick={setTemplate} />
          <Group px={8} w="100%" h="658px" position="center">
            <MobilePreview channel="mobileApp" template={template} />
          </Group>
        </>
      )}
    </Stack>
  )
}

export default Mobile
