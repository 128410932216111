import React from 'react'
import { Image, Card, Text, Stack, SimpleGrid, Button } from '@mantine/core'
import { ContentIntoView } from 'components/ui'

import { ContentContainer, SiteTitle } from 'components/site'
import { useSmallerScreen } from 'hooks'
import { PostOrPage, PostsOrPages } from '@tryghost/content-api'
import { Institute } from 'config/constants'

const Ghost = ({ posts }: { posts?: PostsOrPages }) => {
  const smallerScreen = useSmallerScreen()

  const Item = ({ post }: { post: PostOrPage }) => {
    return (
      <Card
        padding="xl"
        component="a"
        href={post.url}
        target="_blank"
        className="transition-transform hover:scale-105 shadow hover:shadow-lg max-w-[384px]"
      >
        <Card.Section>
          <Image
            src={post.feature_image}
            alt={post.feature_image_alt ?? post.title}
            styles={{
              image: { height: '160px !important' },
            }}
          />
        </Card.Section>
        <Text fw={500} size="lg" mt="md" lineClamp={1}>
          {post.title}
        </Text>
        <Text mt="xs" c="dimmed" size="sm" lineClamp={3}>
          {post.excerpt}
        </Text>
      </Card>
    )
  }

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <ContentContainer>
            <Stack spacing={smallerScreen ? 32 : 64} align="center" pb={smallerScreen ? 60 : 100}>
              <SiteTitle ta="center" text="最新的实践和案例" isIntersecting={isIntersecting} />
              <SimpleGrid cols={smallerScreen ? 1 : 3} spacing="xl" className="px-5">
                {posts?.map((post: PostOrPage) => {
                  return <Item key={post.id} post={post} />
                })}
              </SimpleGrid>
              <Button
                variant="default"
                component="a"
                target="_blank"
                href={Institute}
                style={{
                  height: 48,
                  fontSize: '14px',
                }}
              >
                👉👉 阅读更多信息
              </Button>
            </Stack>
          </ContentContainer>
        )
      }}
    </ContentIntoView>
  )
}

export default Ghost
