import React, { FC } from 'react'
import { CardProps } from 'modules/Site/Home/GoodPoints/type'
import { Group, Image, Stack, Text } from '@mantine/core'
import { iconMap } from 'modules/Site/Home/GoodPoints/constant'

const Card: FC<CardProps> = ({ item }) => {
  return (
    <Stack
      align="center"
      justify="center"
      spacing="sm"
      sx={(theme) => ({
        width: '168px',
        height: '90px',
        background: 'rgba(255, 255, 255, 0.4)',
        border: '1px solid #E3E5E8',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
        borderRadius: theme.radius.md,
      })}
    >
      <Group position="center" align="center" className="w-[32px] h-[32px]">
        <Image src={iconMap[item.icon].src} alt={item.icon} width={26} />
      </Group>
      <Text>{item.text}</Text>
    </Stack>
  )
}

export default Card
