import cls from 'classnames'
import React from 'react'
import { useSmallerScreen } from 'hooks'
import { Box, Flex, Stack, Image, Group } from '@mantine/core'
import { ContentIntoView } from 'components/ui'

import girl7Avatar from 'assets/images/site/avatar/girl7.png'

import introImg from './images/intro.png'
import exampleImg from './images/example.png'
import { ContentContainer, Conversation, FeatureBox, FeatureHeader, GridBackground } from 'components/site'

const AccurateResearch = () => {
  const smallerScreen = useSmallerScreen()

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <GridBackground>
            <ContentContainer className={`${smallerScreen ? 'pb-[77px]' : 'pb-[160px]'}`}>
              <Group position="center">
                <Stack spacing={32} align="flex-start" px={smallerScreen ? 20 : 15} pt={smallerScreen ? 40 : 64}>
                  <FeatureHeader
                    isIntersecting={isIntersecting}
                    title="千人千面，精准调研 "
                    description="灵活设置投放规则，精准用户投放，满足个性化调研和用户触达需求"
                  >
                    <Conversation
                      avatar={girl7Avatar}
                      pcWidth={438}
                      text="只想给刚上完课的学员投放问卷，勾选几下就能设置细颗粒度的投放规则，主打一个精准。"
                    />
                  </FeatureHeader>
                  <FeatureBox color="#293440">
                    <Flex gap={smallerScreen ? 16 : 32} direction={smallerScreen ? 'column' : 'row'}>
                      <Box className="px-[10px] py-0 sm:pl-[16px] sm:py-[16px]">
                        <Box
                          className="py-[16px] sm:py-[36px] px-[16px]"
                          sx={() => ({
                            background: '#FFFFFF',
                            boxShadow: '0px 6.8px 10.2px rgba(0, 0, 0, 0.05)',
                            borderRadius: '16px',
                          })}
                        >
                          <Image
                            src={introImg.src}
                            width={smallerScreen ? '100%' : 464}
                            h={smallerScreen ? 191 : 276}
                            alt="intro"
                          />
                        </Box>
                      </Box>
                      <Box
                        mt={2}
                        className={cls('transformSlideInBottomForImg delay-300', {
                          transformSlideInBottomForImgs: isIntersecting,
                        })}
                      >
                        <Image
                          src={exampleImg.src}
                          width={smallerScreen ? '100%' : 672}
                          h={smallerScreen ? 195 : 380}
                          alt="example"
                        />
                      </Box>
                    </Flex>
                  </FeatureBox>
                </Stack>
              </Group>
            </ContentContainer>
          </GridBackground>
        )
      }}
    </ContentIntoView>
  )
}

export default AccurateResearch
