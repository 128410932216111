import React, { FC, useMemo } from 'react'
import { Box, Select } from '@mantine/core'
import { CampaignTemplate } from 'types/domain'
import { categoryNameMap } from '../constant'

type Props = {
  template: CampaignTemplate
  templates: CampaignTemplate[]
  onClick: (template: CampaignTemplate) => void
}

const TemplateSelector: FC<Props> = ({ template, templates, onClick }) => {
  const options = useMemo(() => {
    return templates.map((template) => {
      return {
        value: template.code,
        label: template.title,
        group: categoryNameMap[template.category],
      }
    })
  }, [templates])

  const handleSelect = (value: string) => {
    const template = templates.find((template) => template.code === value)
    template && onClick(template)
  }

  return (
    <Box px={36} w="100%">
      <Select
        size="md"
        w="100%"
        data={options}
        value={template?.code}
        onChange={handleSelect}
        styles={{
          separatorLabel: {
            fontSize: 16,
          },
        }}
      />
    </Box>
  )
}

export default TemplateSelector
