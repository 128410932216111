import React, { useEffect } from 'react'
import { useSetState } from '@mantine/hooks'
import { Title } from '@mantine/core'
import cls from 'classnames'

import classes from './index.module.scss'

const words = ['收集用户反馈', '有效触达用户', '优化用户体验', '开展用户研究', '支撑产品迭代']

const animationDelay = 2500

let timer: NodeJS.Timer

type State = {
  currentIndex: number
  prevIndex?: number
}

const TextSlider = () => {
  const [state, setState] = useSetState<State>({
    currentIndex: 0,
  })

  const { currentIndex, prevIndex } = state

  const switchWord = () => {
    const prevIndex = currentIndex
    setState({
      prevIndex,
      // currentIndex:  currentIndex === words.length - 1 ? 0 : currentIndex + 1,
      currentIndex: (currentIndex + 1) % words.length,
    })
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  }, [])

  timer = setTimeout(function () {
    switchWord()
  }, animationDelay)

  return (
    <div className={classes.words}>
      {words.map((word, index) => {
        const classNames = cls(classes.word, {
          [classes.visible]: index === currentIndex,
          [classes.hidden]: index === prevIndex,
        })
        return (
          <Title key={index} order={1} align="center" weight={900} className={classNames}>
            {word}
          </Title>
        )
      })}
    </div>
  )
}

export default TextSlider
