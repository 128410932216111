import { Box, Button, Center, Group, Stack } from '@mantine/core'
import Mobile from 'components/business/ChannelPreview/Mobile'
import { SurveyTemplatePreviewApi } from 'components/business/SurveyTemplatePreview'
import { SiteTitle } from 'components/site'
import { Signup } from 'config/constants'
import React, { useEffect, useRef, useState } from 'react'
import { CampaignTemplate } from 'types/domain'
import Templates from './Templates'
import Web from 'components/business/ChannelPreview/Web'
import PreviewActions from 'components/business/PlatformPreview/PreviewActions'
import { PlatformType } from 'components/business/PlatformPreview/type'
import { useRouter } from 'next/router'

type Props = {
  isIntersecting: boolean
  templates: CampaignTemplate[]
}

const WebContent = (props: Props) => {
  const { isIntersecting, templates } = props
  const previewRef = useRef<SurveyTemplatePreviewApi | null>(null)
  const router = useRouter()
  const [template, setTemplate] = useState<CampaignTemplate | null>(null)
  const [platform, setPlatform] = useState<PlatformType>('web')

  useEffect(() => {
    setTemplate(templates[0])
  }, [templates])

  const handleRefresh = () => {
    previewRef.current?.refresh()
  }

  const handleTplApply = () => {
    if (!template?.code) {
      return
    }
    router.push({
      pathname: process.env.NEXT_PUBLIC_APP_URL! + Signup,
      query: {
        templateCode: template.code,
      },
    })
  }

  return (
    <Stack spacing={58} align="center">
      <Stack spacing={12} px={0}>
        <SiteTitle ta="center" text="在数字化系统中体验" isIntersecting={isIntersecting} />
      </Stack>
      <Box
        sx={{
          width: 1200,
          height: '658px',
          background: 'white',
          border: '1px solid #E3E5E8',
          borderRadius: 12,
          padding: 16,
        }}
      >
        <Group position="apart" h="100%" spacing={20} noWrap>
          <Templates templates={templates} template={template} onClick={setTemplate} />
          <Group
            pos="relative"
            align="center"
            w={874}
            h="100%"
            sx={{
              borderRadius: 8,
            }}
            style={{
              width: `${platform === 'web' ? '80%' : ''}`,
            }}
          >
            <PreviewActions
              platformChangeable={true}
              widgetType={template?.widget.type}
              onRefresh={handleRefresh}
              onPlatformClick={setPlatform}
              className="top-0 right-[-60px]"
            />
            <Button onClick={handleTplApply} pos="absolute" className="top-[7px] right-[7px] float-right z-10">
              选用模板
            </Button>
            <Center
              className="bg-white sm:bg-[#F6F8F9] w-full h-full"
              sx={{
                paddingTop: platform !== 'web' ? '10px' : undefined,
                paddingBottom: platform !== 'web' ? '10px' : undefined,
              }}
            >
              {template && (
                <>
                  {platform === 'web' ? (
                    <Web previewRef={previewRef} channel="web" template={template} />
                  ) : (
                    <Mobile previewRef={previewRef} channel="mobileApp" template={template} />
                  )}
                </>
              )}
            </Center>
          </Group>
        </Group>
      </Box>
    </Stack>
  )
}

export default WebContent
