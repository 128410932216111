import React, { FC, useRef, useEffect } from 'react'
import { Group, Stack, ScrollArea } from '@mantine/core'
import { goodPoints1, goodPoints2 } from './constant'
import { Item } from './type'
import Card from './Card'

const MobileSlideItemWidth = 168
const MobileSlideItemGap = 16

type CarouselItem = {
  data: Item[]
}
const CarouselItem: FC<CarouselItem> = ({ data }) => {
  const scrollArea = useRef<HTMLDivElement>(null)
  useEffect(() => {
    scrollArea.current?.scrollTo({
      left: scrollArea.current.scrollWidth / 2 - MobileSlideItemWidth / 2 - MobileSlideItemGap / 2,
      behavior: 'smooth',
    })
  }, [])
  return (
    <ScrollArea viewportRef={scrollArea} type="never" style={{ height: 90 }}>
      <Group noWrap px="sm">
        {data.map((item) => (
          <Card key={item.icon} item={item} />
        ))}
      </Group>
    </ScrollArea>
  )
}
export const CarouselList = () => {
  return (
    <Stack mt={32} spacing="xl">
      <Group className="w-screen overflow-hidden">
        <CarouselItem data={goodPoints1} />
      </Group>
      <Group className="w-screen overflow-hidden" position="center">
        <CarouselItem data={goodPoints2} />
      </Group>
    </Stack>
  )
}
