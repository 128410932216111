import React from 'react'
import { Accordion, Group, List, MantineTheme, Text, Title, useMantineTheme } from '@mantine/core'
import { CampaignTemplate } from 'types/domain'
import { getBackgroundColor, getTextColor } from 'config/themeOverride/getColor'
import { IconArrowNarrowRight } from '@tabler/icons-react'

type Props = {
  value: string
  title: React.ReactNode
  templates: CampaignTemplate[]
  selected?: CampaignTemplate
  onTemplateClick: (template: CampaignTemplate) => void
  collapsible?: boolean
}

type CategoryTitleProps = {
  label: string
  icon?: React.ReactNode
  description: string
}

export const categoryTitleIconProps = (theme: MantineTheme) => {
  return {
    color: getTextColor('brandBlue.6', theme),
  }
}
export const CategoryTitle = ({ label, icon, description }: CategoryTitleProps) => {
  return (
    <Group noWrap align="top">
      {icon}
      <div>
        <Title order={5}>{label}</Title>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  )
}

const CategorySection = ({ value, title, templates, onTemplateClick, selected, collapsible }: Props) => {
  const theme = useMantineTheme()
  return (
    <Accordion.Item value={value}>
      <Accordion.Control className={collapsible ? '' : 'hover:cursor-default'}>{title}</Accordion.Control>
      <Accordion.Panel>
        <List center pl="xs" pt="sm" spacing="xs">
          {templates?.map((template) => {
            const currentSelected = template.code === selected?.code
            return (
              <List.Item
                key={template.code}
                icon={<IconArrowNarrowRight stroke={0.2} />}
                className="cursor-pointer rounded-xs"
                sx={{
                  backgroundColor: currentSelected ? getBackgroundColor('brandBlue.1', theme, 'dark.3') : '',
                  '&:hover': {
                    backgroundColor: currentSelected ? '' : getBackgroundColor('brandBlue.0', theme),
                  },
                }}
                onClick={() => onTemplateClick(template)}
              >
                <Text fw={currentSelected ? 'bold' : 'normal'}>{template.title}</Text>
              </List.Item>
            )
          })}
        </List>
      </Accordion.Panel>
    </Accordion.Item>
  )
}

export default CategorySection
