import React, { FC } from 'react'
import { Box, Flex, Group, Stack } from '@mantine/core'
import { ContentIntoView } from 'components/ui'
import cls from 'classnames'
import Image from 'next/image'

import { SiteTitle } from 'components/site'

import gcLogo from './images/web/gc.png'
import mdLogo from './images/web/md.png'
import cktLogo from './images/web/ckt.png'
import yzwLogo from './images/web/yzw.png'
import xdmLogo from './images/web/xdm.png'
import yowantLogo from './images/web/yw.png'
import jsjLogo from './images/web/jsj.png'
import pgyLogo from './images/web/pgy.png'
import beeartLogo from './images/web/beeart.png'
import cxLogo from './images/web/cx.png'
import eclickLogo from './images/web/eclick.png'
import jyLogo from './images/web/jy.png'
import uCloudLogo from './images/web/ucloud.png'
import mzLogo from './images/web/mz.png'
import qingCloudLogo from './images/web/qingCloud.png'

import gcSmLogo from './images/mobile/gc.png'
import mdSmLogo from './images/mobile/md.png'
import cktSmLogo from './images/mobile/ckt.png'
import yzwSmLogo from './images/mobile/yzw.png'
import xdmSmLogo from './images/mobile/xdm.png'
import yowantSmLogo from './images/mobile/yw.png'
import jsjSmLogo from './images/mobile/jsj.png'
import pgySmLogo from './images/mobile/pgy.png'
import beeartSmLogo from './images/mobile/beeart.png'
import cxSmLogo from './images/mobile/cx.png'
import eclickSmLogo from './images/mobile/eclick.png'
import jySmLogo from './images/mobile/jy.png'
import uCloudSmLogo from './images/mobile/ucloud.png'
import mzSmLogo from './images/mobile/mz.png'
import qingCloudSmLogo from './images/mobile/qingCloud.png'

type Props = {
  smallerScreen: boolean
  hiddenTitle?: boolean
}

const TheyUseIt: FC<Props> = ({ hiddenTitle = false, smallerScreen }) => {
  const groupLogo = [
    [uCloudLogo, jyLogo, eclickLogo, gcLogo, cxLogo],
    [mdLogo, cktLogo, yzwLogo, xdmLogo, beeartLogo],
    [yowantLogo, jsjLogo, pgyLogo, mzLogo, qingCloudLogo],
  ]

  const smLogo = [
    [uCloudSmLogo, jySmLogo, eclickSmLogo, gcSmLogo, cxSmLogo],
    [mdSmLogo, cktSmLogo, yzwSmLogo, xdmSmLogo, beeartSmLogo],
    [yowantSmLogo, jsjSmLogo, pgySmLogo, mzSmLogo, qingCloudSmLogo],
  ]

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <Stack justify="center" align="center" spacing={64} className="pb-[66px] sm:pb-[127px]">
            {!hiddenTitle && (
              <SiteTitle
                ta="center"
                isIntersecting={isIntersecting}
                text={
                  smallerScreen ? (
                    <>
                      优秀的产品团队
                      <br />
                      正在使用浩客
                    </>
                  ) : (
                    '优秀的产品团队，正在使用浩客'
                  )
                }
              />
            )}
            <Box
              className={cls('transformSlideInBottomBase delay-300', {
                transformSlideInBottom: isIntersecting,
              })}
            >
              {smallerScreen ? (
                <Flex wrap="wrap" justify="space-between" gap="50px 0" className="px-[20px] sm:px-0">
                  {smLogo.flat().map((logo) => (
                    <Image src={logo.src} key={logo.src} width={160} height={40} alt="logo" />
                  ))}
                </Flex>
              ) : (
                <Stack spacing={48}>
                  {groupLogo.map((logos) => {
                    return (
                      <Group key={logos[0].src} spacing={64}>
                        {logos.map((logo) => (
                          <Image src={logo.src} key={logo.src} width={180} height={36} alt="logo" />
                        ))}
                      </Group>
                    )
                  })}
                </Stack>
              )}
            </Box>
          </Stack>
        )
      }}
    </ContentIntoView>
  )
}

export default TheyUseIt
