import { useSmallerScreen } from 'hooks'
import React from 'react'
import { Box, Stack, Title } from '@mantine/core'
import cls from 'classnames'
import CardList from './CardList'
import { CarouselList } from './CarouselList'
import { ContentIntoView } from 'components/ui'

const GoodPoints = () => {
  const smallerScreen = useSmallerScreen()

  return (
    <ContentIntoView rootMargin="0px 0px -200px 0px">
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <Stack pt={smallerScreen ? 120 : 168} pb={96} justify="center" align="center">
            <Title
              ta="center"
              size={smallerScreen ? 32 : 40}
              px={smallerScreen ? 36 : 'unset'}
              className={cls('transformSlideInBottomBase delay-150', {
                transformSlideInBottom: isIntersecting,
              })}
            >
              这些小事，我们当然也考虑到了
            </Title>
            <Box
              className={cls('transformSlideInBottomBase delay-300', {
                transformSlideInBottom: isIntersecting,
              })}
            >
              {smallerScreen ? <CarouselList /> : <CardList />}
            </Box>
          </Stack>
        )
      }}
    </ContentIntoView>
  )
}

export default GoodPoints
