import React, { FC, useMemo } from 'react'
import { Accordion, Group, ScrollArea, Stack, Text, useMantineTheme } from '@mantine/core'
import { IconArrowRight } from '@tabler/icons-react'
import { getTextColor } from 'config/themeOverride/getColor'
import { useRouter } from 'next/router'
import { groupBy } from 'lodash-es'
import { Templates as TemplatesLink } from 'config/constants'
import { CampaignTemplate } from 'types/domain'
import { categoryNameMap } from '../constant'
import CategorySection, { CategoryTitle } from 'components/business/CampaigTemplate/CategorySection'

type Props = {
  template: CampaignTemplate | null
  templates: CampaignTemplate[]
  onClick: (template: CampaignTemplate) => void
}

const Templates: FC<Props> = (props) => {
  const theme = useMantineTheme()
  const router = useRouter()
  const { template, templates, onClick } = props

  const groupTemplates = useMemo(() => {
    return groupBy(templates, 'category')
  }, [templates])

  const handleJumpToTemplates = () => {
    router.push(TemplatesLink)
  }

  return (
    <Stack
      h="100%"
      w="274px"
      p="sm"
      justify="space-between"
      sx={{
        flexShrink: 0,
        background: '#F6F8F9',
        borderRadius: '8px',
      }}
    >
      {template && (
        <ScrollArea h={650} scrollbarSize={3} pr="sm">
          <Accordion variant="separated" defaultValue={template.category}>
            {Object.keys(groupTemplates ?? {})?.map((category) => {
              return (
                <CategorySection
                  key={category}
                  value={category}
                  collapsible={true}
                  title={<CategoryTitle label={categoryNameMap[category]} description="" />}
                  templates={groupTemplates?.[category] as []}
                  selected={template}
                  onTemplateClick={onClick}
                />
              )
            })}
          </Accordion>
        </ScrollArea>
      )}
      <Group spacing={4} className="cursor-pointer group" onClick={handleJumpToTemplates}>
        <Text size={16}>💡 更多模板</Text>
        <IconArrowRight
          size={18}
          stroke={2}
          color={getTextColor('gray.9', theme)}
          className="mr-[4px] ease-in duration-200 transition-all group-hover:translate-x-[4px]"
        />
      </Group>
    </Stack>
  )
}

export default Templates
