import React from 'react'
import cls from 'classnames'
import { useSmallerScreen } from 'hooks'
import { Anchor, Box, Center, Flex, Group, Image, Stack, Text, Title } from '@mantine/core'

import { ContentIntoView } from 'components/ui'
import { ContentContainer, Conversation, FeatureBox, FeatureCard, FeatureHeader } from 'components/site'

import girl9Avatar from 'assets/images/site/avatar/girl9.png'
import platformImg from './images/platform.png'
import installImg from './images/install.png'
import styleImg from './images/style.png'
import jsImg from './images/js.png'
import iosImg from './images/ios.png'
import androidImg from './images/android.png'
import miniappImg from './images/miniapp.png'
import linkImg from './images/link.png'
import reactImg from './images/react.png'
import vueImg from './images/vue.png'
import uniappImg from './images/uniapp.png'
import taraImg from './images/tara.png'
import swfitImg from './images/swift.png'

const firstRow = [
  {
    img: jsImg.src,
    link: 'https://howxm.com/help/articles/web-sdk-intro',
  },
  { img: iosImg.src, link: 'https://howxm.com/help/articles/ios-x-sdk' },
  { img: androidImg.src, link: 'https://howxm.com/help/articles/x-sdk-andriod' },
  { img: miniappImg.src, link: 'https://howxm.com/help/articles/x-sdk-miniapp' },
  { img: linkImg.src, link: 'https://howxm.com/help/articles/distribution-link' },
]
const secondRow = [
  {
    img: reactImg.src,
    link: 'https://howxm.com/help/articles/npm-web-sdk-intro',
  },
  { img: vueImg.src, link: 'https://howxm.com/help/articles/npm-web-sdk-intro' },
  { img: uniappImg.src, link: 'https://howxm.com/help/articles/x-sdk-uniapp' },
  { img: taraImg.src, link: 'https://howxm.com/help/articles/x-sdk-miniapp#2-taro' },
  { img: swfitImg.src, link: 'https://github.com/howxm/howxm-ios-swift-demo' },
]
const ImageLink = ({ link, img }: { link: string; img: any }) => {
  return (
    <Anchor key={link} href={link} target="_blank">
      <Center
        className="hover:cursor-pointer"
        sx={() => ({
          width: '56px',
          height: '56px',
          border: '1px solid #E3E5E8',
          borderRadius: '8px',
        })}
      >
        <Image src={img} width={28} height={28} alt={link} />
      </Center>
    </Anchor>
  )
}

const QuicklyUse = () => {
  const smallerScreen = useSmallerScreen()

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <ContentContainer>
            <Group
              position="center"
              px={smallerScreen ? 15 : 0}
              pt={smallerScreen ? 30 : 110}
              pb={smallerScreen ? 80 : 110}
            >
              <Stack spacing={32} align="flex-start">
                <FeatureHeader
                  isIntersecting={isIntersecting}
                  title="10 分钟上线，告别漫长研发排期"
                  description="减少产研介入，全局控制，灵活上下线，PM、用研、运营等业务一人全搞定"
                >
                  <Conversation
                    avatar={girl9Avatar}
                    pcWidth={438}
                    text="之前每次产品内调研光排期就两周，现在用浩客在网站/APP/小程序投放问卷，一天就够了！后续还能随时调整题目、样式、发布状态，实在太方便~"
                  />
                </FeatureHeader>
                <FeatureBox color="#F3F6FA">
                  <Flex
                    justify="space-between"
                    direction={smallerScreen ? 'column' : 'row'}
                    w={smallerScreen ? '100%' : 1200}
                    h={smallerScreen ? 474 : 380}
                  >
                    <Stack
                      pt={smallerScreen ? 32 : 64}
                      pb={smallerScreen ? 0 : 64}
                      px={smallerScreen ? 32 : 64}
                      justify="space-between"
                    >
                      <Stack spacing={8}>
                        <Title
                          size={24}
                          className={cls('transformSlideInBottomBase delay-300', {
                            transformSlideInBottom: isIntersecting,
                          })}
                        >
                          多终端收集，全平台支持
                        </Title>
                        <Text
                          className={cls('transformSlideInBottomBase delay-300', {
                            transformSlideInBottom: isIntersecting,
                          })}
                        >
                          Web/App/小程序，用户在哪里，就在哪里收集。浩客 X-SDK
                          支持所有主流的技术语言架构，集成简单，技术友好是我们所追求的目标。
                        </Text>
                      </Stack>
                      <Group spacing={8} noWrap>
                        {firstRow.map(({ img, link }) => (
                          <ImageLink key={link} link={link} img={img} />
                        ))}
                      </Group>
                      <Group spacing={8} noWrap>
                        {secondRow.map(({ img, link }) => (
                          <ImageLink key={img} link={link} img={img} />
                        ))}
                      </Group>
                    </Stack>
                    <Box
                      className={cls('transformSlideInBottomBaseForImg delay-300', {
                        transformSlideInBottomForImg: isIntersecting,
                      })}
                    >
                      <Image
                        src={platformImg.src}
                        alt="platform"
                        width={smallerScreen ? '100%' : 700}
                        height={smallerScreen ? 186 : 378}
                      />
                    </Box>
                  </Flex>
                </FeatureBox>
                <Flex gap={32} direction={smallerScreen ? 'column' : 'row'}>
                  <FeatureCard
                    color="#F3F6FA"
                    isIntersecting={isIntersecting}
                    h={smallerScreen ? 382 : 509}
                    title="轻松安装 SDK"
                    description={
                      smallerScreen
                        ? ['把一段 SDK 代码或组件包集成到系统中，部署，上线，Bang！数据立刻源源不断流进来。']
                        : ['把一段 SDK 代码或组件包集成到系统中 , 部署，上线，Bang！', '数据立刻源源不断流进来。']
                    }
                  >
                    <Image
                      src={installImg.src}
                      alt="platform"
                      width={smallerScreen ? '100%' : 584}
                      height={smallerScreen ? 191 : 324}
                    />
                  </FeatureCard>
                  <FeatureCard
                    color="#F3F6FA"
                    isIntersecting={isIntersecting}
                    h={smallerScreen ? 382 : 509}
                    title="按需设计问卷"
                    description={['丰富的题目组件和场景模板，自定义样式，你的问卷你做主。']}
                  >
                    <Image
                      src={styleImg.src}
                      alt="platform"
                      width={smallerScreen ? '100%' : 584}
                      height={smallerScreen ? 191 : 324}
                    />
                  </FeatureCard>
                </Flex>
              </Stack>
            </Group>
          </ContentContainer>
        )
      }}
    </ContentIntoView>
  )
}

export default QuicklyUse
