import { useSmallerScreen } from 'hooks'
import React, { FC, useState } from 'react'
import { isEmpty } from 'lodash-es'
import { ContentContainer, GridBackground } from 'components/site'

import { ContentIntoView } from 'components/ui'
import { CampaignTemplate, useCampaignTemplatesQuery } from 'types/domain'
import MobileContent from './MobileContent'
import WebContent from './WebContent'

const Example: FC = () => {
  const smallerScreen = useSmallerScreen()
  const [templates, setTemplates] = useState<CampaignTemplate[]>([])

  useCampaignTemplatesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      if (!isEmpty(data?.campaignTemplates)) {
        setTemplates(data?.campaignTemplates as CampaignTemplate[])
      }
    },
  })

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <GridBackground>
            <ContentContainer className="pt-[32px] pb-[60px]">
              {smallerScreen ? (
                <MobileContent isIntersecting={isIntersecting} templates={templates} />
              ) : (
                <WebContent isIntersecting={isIntersecting} templates={templates} />
              )}
            </ContentContainer>
          </GridBackground>
        )
      }}
    </ContentIntoView>
  )
}

export default Example
