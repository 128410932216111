import { Box, Button, Center, Grid, Group, Image, MediaQuery, Stack, Text, Title, useMantineTheme } from '@mantine/core'
import { useSmallerScreen } from 'hooks'
import Link from 'next/link'
import cls from 'classnames'
import { useRouter } from 'next/router'

import { Signup } from 'config/constants'
import { ContentContainer } from 'components/site'
import { Icon } from 'components/ui'
import HappyPeople from 'assets/images/site/happy-people.png'
import Dialogue1 from 'assets/images/site/dialogue-1.png'
import Dialogue2 from 'assets/images/site/dialogue-2.png'
import Dialogue3 from 'assets/images/site/dialogue-3.png'
import Dialogue4 from 'assets/images/site/dialogue-4.png'
import BrightEyes from 'assets/images/site/bright-eyes.png'
import Bg from 'assets/images/site/bg.jpg'
import TextSlider from './TextSlider'

import styles from './index.module.scss'

const Jumbotron = () => {
  const { breakpoints } = useMantineTheme()
  const { query } = useRouter()
  const smallScreen = useSmallerScreen()

  const handleTryClick = () => {
    window.scrollTo({
      top: smallScreen ? 1500 : 1380,
      left: 0,
      behavior: 'smooth',
    })
  }
  return (
    <Box className={styles.container} style={{ backgroundImage: `url(${Bg.src})` }}>
      <ContentContainer>
        <Box className="relative">
          <Group position="center">
            <Stack spacing={0}>
              <Group spacing={4} position="center">
                <Icon
                  name="star2"
                  className={styles.star}
                  style={{
                    animationDelay: '1s',
                  }}
                />
                <Icon
                  name="star2"
                  className={styles.star}
                  style={{
                    animationDelay: '1.15s',
                  }}
                />
                <Icon
                  name="star2"
                  className={styles.star}
                  style={{
                    animationDelay: '1.3s',
                  }}
                />
                <Icon
                  name="star2"
                  className={styles.star}
                  style={{
                    animationDelay: '1.45s',
                  }}
                />
                <Icon
                  name="star2"
                  className={styles.star}
                  style={{
                    animationDelay: '1.6s',
                  }}
                />
              </Group>
              <Center>
                <Image
                  src={HappyPeople.src}
                  width={476}
                  imageProps={{ className: 'max-w-[100vw]' }}
                  className={cls('max-w-[100vw]', styles.happyPeople)}
                  alt=""
                />
              </Center>
              <Box className={cls('slideInBottom', styles.titleAndDesc)}>
                <TextSlider />
                <Title
                  order={1}
                  align="center"
                  mb={12}
                  weight={900}
                  className="xs:!text-[56px] !text-[32px] xs:!leading-[81px] !leading-[48px]"
                >
                  从未如此简单
                </Title>
                <MediaQuery
                  query={`(max-width: ${breakpoints.xs})`}
                  styles={{ fontSize: 16, paddingLeft: '2.25rem!important', paddingRight: '2.25rem!important' }}
                >
                  <Text size={18} color="gray.9" mb={36} align="center">
                    在产品内嵌入轻量级问卷，轻松捕捉用户想法，改善服务体验
                  </Text>
                </MediaQuery>
                <Center>
                  <Grid gutter={0} className="px-[60px] xs:px-0 w-[369px]">
                    <Grid.Col span={12} xs={4} className="!mb-[6px] xs:!pr-[12px]">
                      <Link href={{ pathname: Signup, query }} passHref legacyBehavior>
                        <Button
                          fullWidth
                          size="lg"
                          px="md"
                          component="a"
                          styles={{
                            label: {
                              fontSize: 14,
                            },
                          }}
                        >
                          免费注册
                        </Button>
                      </Link>
                    </Grid.Col>
                    <Grid.Col span={12} xs={8} className="xs:!pl-[12px]">
                      <Box className="relative">
                        <Button
                          leftIcon={<Icon name="sparkles" />}
                          fullWidth
                          size="lg"
                          variant="default"
                          styles={{
                            label: {
                              fontSize: 14,
                            },
                          }}
                          onClick={handleTryClick}
                        >
                          体验一下
                        </Button>
                        <Image
                          src={BrightEyes.src}
                          width={163}
                          className={cls('absolute left-1/2 top-11 hidden xs:inline-block scaleIn', styles.tryTip)}
                          alt=""
                        />
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Center>
              </Box>
            </Stack>
          </Group>
          <Box className="absolute left-1/2 top-0 translate-x-[250px] hidden md:inline-block">
            <Image className={cls('slideInBottom', styles.dialogue)} src={Dialogue1.src} width={308} alt="" />
          </Box>
          <Box className="absolute left-1/2 bottom-[-70px] translate-x-[-550px] hidden md:inline-block">
            <Image className={cls('slideInBottom', styles.dialogue)} src={Dialogue2.src} width={308} alt="" />
          </Box>
          <Box className="absolute left-1/2 top-0 translate-x-[-610px] hidden md:inline-block">
            <Image className={cls('slideInBottom', styles.dialogue)} src={Dialogue3.src} width={308} alt="" />
          </Box>
          <Box className="absolute left-1/2 bottom-[-70px] translate-x-[260px] hidden md:inline-block">
            <Image className={cls('slideInBottom', styles.dialogue)} src={Dialogue4.src} width={308} alt="" />
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  )
}

export default Jumbotron
