import React from 'react'
import { useSmallerScreen } from 'hooks'
import { Flex, Group, Image, Stack } from '@mantine/core'
import { ContentIntoView } from 'components/ui'

import { ContentContainer, Conversation, FeatureCard, FeatureHeader } from 'components/site'
import girl12Avatar from 'assets/images/site/avatar/girl12.png'

import aiImg from './images/ai.png'
import userSegmentImg from './images/userSegment.png'
import webhookImg from './images/webhook.png'

const NewFeature = () => {
  const smallerScreen = useSmallerScreen()

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <ContentContainer>
            <Group position="center" className="pt-[45px] sm:pt-[168px] px-[20px] sm:px-0">
              <Stack spacing={32} align="flex-start">
                <FeatureHeader
                  isIntersecting={isIntersecting}
                  title="AI 加持，闭环管理用户反馈"
                  description="没有 AI 能力的产品是不完整的，浩客还有更多实用的能力模块"
                >
                  <Conversation
                    avatar={girl12Avatar}
                    pcWidth={438}
                    text={[
                      '做问卷和数据分析，挺头大的，AI 帮我搞定！产品模块还挺多，帮我闭环管理用户，不错过任何消息。',
                    ]}
                  />
                </FeatureHeader>
                <Flex>
                  <FeatureCard
                    w={smallerScreen ? '100%' : 1200}
                    h={smallerScreen ? 382 : 380}
                    isIntersecting={isIntersecting}
                    title="AI Powered：让你省心省力"
                    direction={smallerScreen ? 'column' : 'row'}
                    description={
                      smallerScreen
                        ? ['浩客已经全面接入 AI 的能力，让 AI 帮你制作问卷，美化样式，甚至帮你自动化出一份分析报告。']
                        : [
                            '浩客已经全面接入 AI 的能力，让 AI 帮你制作问卷，美化',
                            '样式，甚至帮你自动化出一份分析报告。',
                          ]
                    }
                  >
                    <Image
                      src={aiImg.src}
                      alt="ai powered"
                      width={smallerScreen ? '100%' : 672}
                      height={smallerScreen ? 195 : 378}
                    />
                  </FeatureCard>
                </Flex>
                <Flex gap={32} direction={smallerScreen ? 'column' : 'row'}>
                  <FeatureCard
                    isIntersecting={isIntersecting}
                    h={smallerScreen ? 382 : 509}
                    direction="column"
                    title="用户群组：关注每一个用户"
                    description={
                      smallerScreen
                        ? [
                            '自动汇总应用内用户所有的反馈调研记录，丰富用户画像，并可针对不同的群组进行后续的调研和互动。',
                          ]
                        : [
                            '自动汇总应用内用户所有的反馈调研记录，丰富用户画像，',
                            '并可针对不同的群组进行后续的调研和互动。',
                          ]
                    }
                  >
                    <Image
                      src={userSegmentImg.src}
                      alt="user segment"
                      width={smallerScreen ? '100%' : 584}
                      height={smallerScreen ? 191 : 324}
                    />
                  </FeatureCard>
                  <FeatureCard
                    h={smallerScreen ? 382 : 509}
                    direction="column"
                    isIntersecting={isIntersecting}
                    title="推送与集成：不错过任何一条反馈"
                    description={
                      smallerScreen
                        ? [
                            '反馈数据可直接推送至企业微信、钉钉、飞书中，或通过 Webhook 推送到自有 IT 进行后续数据留档和处理。',
                          ]
                        : [
                            '反馈数据可直接推送至企业微信、钉钉、飞书中，或通过',
                            'Webhook 推送到自有 IT 进行后续数据留档和处理。',
                          ]
                    }
                  >
                    <Image
                      src={webhookImg.src}
                      alt="webhook"
                      width={smallerScreen ? '100%' : 584}
                      height={smallerScreen ? 191 : 324}
                    />
                  </FeatureCard>
                </Flex>
              </Stack>
            </Group>
          </ContentContainer>
        )
      }}
    </ContentIntoView>
  )
}

export default NewFeature
