import React from 'react'
import { Group, Stack } from '@mantine/core'

import { goodPoints1, goodPoints2 } from './constant'
import Card from './Card'

const CardList = () => {
  return (
    <Stack mt={46} spacing="xl">
      <Group spacing="xl" position="center">
        {goodPoints1.map((item) => (
          <Card key={item.icon} item={item} />
        ))}
      </Group>
      <Group spacing="xl" position="center">
        {goodPoints2.map((item) => (
          <Card key={item.icon} item={item} />
        ))}
      </Group>
    </Stack>
  )
}

export default CardList
