import { useSmallerScreen } from 'hooks'
import React, { FC } from 'react'
import cls from 'classnames'
import { Box, Center, Group, Stack, Text, Image, Flex } from '@mantine/core'

import cktAvatar from './images/ckt.png'
import jsjAvatar from './images/jsj.png'
import cxhubAvatar from './images/cxhub.png'
import uCloudAvatar from './images/uCloud.png'
import cxAvatar from './images/cx.png'
import beeArtAvatar from './images/beeArt.png'
import { ContentIntoView } from 'components/ui'
import { ContentContainer, SiteTitle } from 'components/site'

type Customer = {
  avatar: any
  name: string
  account: string
  title: string
  content: string
  className?: string
}

type ItemProps = {
  customer: Customer
  isIntersecting?: boolean
}

const Item: FC<ItemProps> = (props) => {
  const smallerScreen = useSmallerScreen()
  const { avatar, name, account, title, content, className = '' } = props.customer

  return (
    <Box
      className={cls('transformSlideInBottomBase delay-300', className, {
        '!mt-0': smallerScreen,
        transformSlideInBottom: props.isIntersecting,
      })}
      sx={() => ({
        display: 'inline-block',
        width: smallerScreen ? '100%' : '376px',
        background: '#F6F8F9',
        borderRadius: '8px',
        padding: '24px',
      })}
    >
      <Stack spacing={8}>
        <Group spacing={10}>
          <Center>
            <Image src={avatar.src} alt={name} width={64} height={64} />
          </Center>
          <Stack spacing={0} c="#495057">
            <Text>{name}</Text>
            <Text>
              @&nbsp;{account}&nbsp;&nbsp;{title}
            </Text>
          </Stack>
        </Group>
        <Text size={16}>{content}</Text>
      </Stack>
    </Box>
  )
}

const customers: Customer[] = [
  {
    avatar: cktAvatar,
    name: '赵文文',
    account: '创客贴',
    title: '产品经理',
    content: '问卷上线第一周，我们就收到上万条数据，回收率高了3倍不止！',
  },
  {
    avatar: jsjAvatar,
    name: '万钒',
    account: '金数据',
    title: '产品经理',
    content: '网站和小程序集成浩客只花了不到半天的时间，现在在产品内发一个问卷实在太方便了！',
  },
  {
    avatar: beeArtAvatar,
    name: '笪磊',
    account: 'BeeArt',
    title: '产品经理',
    content: '我们通过浩客给不同的用户群投放调研问卷，收集到更加精准有效的用户反馈。',
  },
  {
    avatar: uCloudAvatar,
    name: 'Lai',
    account: 'UCloud',
    title: '用户研究',
    content: '浩客的投放机制非常灵活，现在常规的体验指标我们都用浩客来收集，对用户也不会造成打扰。',
  },
  {
    avatar: cxAvatar,
    name: '雪岩',
    account: '彩漩',
    title: '产品经理',
    content: '浩客让我们在产品的早期阶段获得了非常多来自客户的宝贵反馈，这些信息将会指导我们产品的开发过程。',
  },
  {
    avatar: cxhubAvatar,
    name: '龙国富',
    account: 'CXHub',
    title: '主理人',
    content: '浩客让我们内部不再是“我感觉”或“我认为”这类型拍脑袋的无效讨论，轻松捕获用户的真实想法，用量化数据说话。',
  },
]

const CustomerSay = () => {
  const smallerScreen = useSmallerScreen()

  return (
    <ContentIntoView>
      {(entry) => {
        const { isIntersecting } = entry || {}
        return (
          <ContentContainer>
            <Stack spacing={smallerScreen ? 32 : 64} pb={smallerScreen ? 60 : 100}>
              <SiteTitle ta="center" text="朋友们这么说" isIntersecting={isIntersecting} />
              <Flex
                direction={smallerScreen ? 'column' : 'row'}
                columnGap={smallerScreen ? 0 : 24}
                rowGap={smallerScreen ? 8 : 24}
                justify="center"
                align="flex-start"
                wrap="wrap"
                px={smallerScreen ? 20 : 0}
              >
                {customers.map((customer, index) => (
                  <Item customer={customer} key={index} isIntersecting={isIntersecting} />
                ))}
              </Flex>
            </Stack>
          </ContentContainer>
        )
      }}
    </ContentIntoView>
  )
}

export default CustomerSay
