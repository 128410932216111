import cloudImg from './images/cloud.png'
import codeImg from './images/code.png'
import cupImg from './images/cup.png'
import easyImg from './images/easy.png'
import entriesImg from './images/entries.png'
import eventImg from './images/event.png'
import pwdImg from './images/pwd.png'
import roleImg from './images/role.png'
import secretImg from './images/secret.png'
import updateImg from './images/update.png'
import userImg from './images/user.png'
import { Item } from './type'

export const goodPoints1: Item[] = [
  {
    icon: 'entries',
    text: '数据实时回收',
  },
  {
    icon: 'cloud',
    text: '顶级云服务提供商',
  },
  {
    icon: 'pwd',
    text: '银行级数据加密',
  },
  {
    icon: 'secret',
    text: '等保3.0',
  },
  {
    icon: 'update',
    text: '每周更新',
  },
  {
    icon: 'cup',
    text: '十年积累',
  },
]

export const goodPoints2: Item[] = [
  {
    icon: 'easy',
    text: '简单易用',
  },
  {
    icon: 'code',
    text: '无需开发',
  },
  {
    icon: 'event',
    text: '事件传入',
  },
  {
    icon: 'user',
    text: '多人协作',
  },
  {
    icon: 'role',
    text: '权限机制',
  },
]

export const iconMap: any = {
  entries: entriesImg,
  cloud: cloudImg,
  pwd: pwdImg,
  secret: secretImg,
  update: updateImg,
  cup: cupImg,
  easy: easyImg,
  code: codeImg,
  event: eventImg,
  user: userImg,
  role: roleImg,
}
